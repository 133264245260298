<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="border px-5 pt-5 mb-5 rounded">
                    <div class="row">
                        <div class="col-6 col-sm-4 col-md-4 col-xl-4 col-lg-4">
                            <b-form-group :label="$t('national_id')">
                                {{ applicationData != null ? applicationData.national_id : '-' }}
                            </b-form-group>
                        </div>
                        <div class="col-6 col-sm-4 col-md-4 col-xl-4 col-lg-4">
                            <b-form-group :label="$t('name')">
                                {{ applicationData != null ? applicationData.name : '-' }}
                            </b-form-group>
                        </div>
                        <div class="col-6 col-sm-4 col-md-4 col-xl-4 col-lg-4">
                            <b-form-group :label="$t('surname')">
                                {{ applicationData != null ? applicationData.surname : '-' }}
                            </b-form-group>
                        </div>
                        <div class="col-6 col-sm-4 col-md-4 col-xl-4 col-lg-4">
                            <b-form-group :label="$t('mobile_number')">
                                {{ applicationData != null ? applicationData.phone : '-' }}
                            </b-form-group>
                        </div>
                        <div class="col-6 col-sm-4 col-md-4 col-xl-4 col-lg-4">
                            <b-form-group :label="$t('email')">
                                {{ applicationData != null ? applicationData.email : '-' }}
                            </b-form-group>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 d-flex flex-column flex-md-row mb-4 border-box">
                <b-button @click="formStatus = true" variant="success" size="sm">{{ $t('meeting_add') }}</b-button>
            </div>
            <div class="col-12 mb-4" v-show="formStatus">
                <div class="border px-5 pt-5 mb-5 rounded">
                    <ValidationObserver ref="meetingForm">
                        <div class="row">
                            <div class="col-12 col-sm-4 col-md-4">
                                <ValidationProvider name="nextCallDate" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('meeting_planed_date')">
                                        <v-date-picker
                                            v-model="meetingForm.nextCallDate"
                                            :locale="'en'"
                                            is-expanded
                                            :popover="{ 'visibility': 'click' }">
                                            <template
                                                v-slot="{ inputValue, inputEvents }">
                                                <b-input-group>
                                                    <b-form-input
                                                        :readonly="true"
                                                        :value="inputValue"
                                                        v-on="inputEvents"
                                                        :state="errors[0] ? false : null"
                                                    ></b-form-input>
                                                    <b-input-group-append>
                                                        <b-button
                                                            variant="outline-secondary"
                                                            class="btn-40"
                                                            disabled
                                                        >
                                                            <i class="ri-calendar-line"></i
                                                            ></b-button>
                                                    </b-input-group-append>
                                                    <b-form-invalid-feedback v-if="errors[0]"
                                                                             v-html="errors[0]"></b-form-invalid-feedback>
                                                </b-input-group>
                                            </template>
                                        </v-date-picker>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-sm-4 col-md-4">
                                <ValidationProvider name="meetingType" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('meeting_type')">
                                        <parameter-selectbox v-model="meetingForm.meetingType"
                                                             code="graduate_application_meeting_type"
                                                             :validate-error="errors[0]"></parameter-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-sm-4 col-md-4">
                                <ValidationProvider name="meetingStatus" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('meeting_status')">
                                        <parameter-selectbox v-model="meetingForm.meetingStatus"
                                                             code="graduate_application_meeting_status"
                                                             :validate-error="errors[0]"></parameter-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12">
                                <ValidationProvider name="meetingDescription" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('meeting_description')">
                                        <b-form-textarea v-model="meetingForm.meetingDescription"
                                                         :class="errors[0] ? 'is-invalid':''"
                                                         rows="4"/>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 mb-4">
                                <b-button @click="saveMeeting" variant="primary" size="sm">{{ $t('save') }}</b-button>
                            </div>
                        </div>
                    </ValidationObserver>
                </div>
            </div>
            <div class="col-12">
                <b-table
                    :empty-text="this.$t('there_are_no_records')"
                    bordered
                    hover
                    striped
                    responsive
                    :items="meetings"
                    :fields="meetingsFields"
                    show-empty
                    class="mb-5 table-dropdown no-scrollbar border rounded">
                </b-table>
            </div>
            <CommonModal ref="formModal"
                         size="md"
                         :onHideOnlyX="true"
                         v-if="checkPermission('graduateapplicationmeeting_excelimport')">
                <template v-slot:CommonModalTitle>
                    {{ $t('import') }}
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="importForm">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                            <ValidationProvider name="file" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('excel_file')">
                                    <div class="d-flex custom-file-upload">
                                        <b-form-file
                                            v-model="form.file"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            :placeholder="$t('select_file')"
                                            :drop-placeholder="$t('drop_file')"
                                            ref="fileInput">
                                        </b-form-file>
                                        <b-button variant="outline-secondary"
                                                  @click="$refs.fileInput.$el.childNodes[0].click();">{{ $t('browse') }}
                                        </b-button>
                                    </div>
                                    <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                    </ValidationObserver>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                        <b-form-group>
                            <b-button variant="primary" @click="sendFile">{{ $t('import') || toUpperCase }}</b-button>
                        </b-form-group>
                    </div>
                </template>
            </CommonModal>
        </div>
    </div>
</template>

<script>
// Components
import CommonModal from "@/components/elements/CommonModal";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
//Pages
// Services
import GraduateApplicationService from "@/services/GraduateApplicationService";
// Other
import {ValidationObserver, ValidationProvider} from "vee-validate"
import qs from "qs";
import moment from "moment";
export default {
    components: {
        CommonModal,
        ParameterSelectbox,

        ValidationProvider,
        ValidationObserver,
    },
    props: {
        graduateApplicationId: {
            type: Number,
        },
    },
    data() {
        return {
            formStatus: false,
            form: {},
            meetings: [],
            meetingsFields: [
                {
                    key: 'date',
                    label: this.$t('meeting_date'),
                },
                {
                    key: 'doingPerson',
                    label: this.$t('meeting_doing_person'),
                },
                {
                    key: 'planedDate',
                    label: this.$t('meeting_planed_date'),
                },
                {
                    key: 'meetingContent',
                    label: this.$t('meeting_content'),
                },
                {
                    key:'responsiblePerson',
                    label:this.$t('responsible_person'),

                }
            ],
            meetingForm: {
                nextCallDate: null,
                meetingType: null,
                meetingStatus: null,
                meetingDescription: null
            },
            applicationData: null
        }
    },
    watch: {},
    created() {
        if (this.graduateApplicationId) {
            this.get(this.graduateApplicationId)
        }
    },
    methods: {
        get(graduateApplicationId) {
            this.meetings = []
            const config = {
                params: {
                    filter: {
                        graduate_application_id: graduateApplicationId
                    },
                    limit: -1
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            }
            GraduateApplicationService.meetingsGetAll(config).then(response => {
                if(response.data.data.length > 0) {
                    this.applicationData = response.data.data[0].graduate_application
                }
                response.data.data.forEach(itm => {
                    this.meetings.push({
                        date: itm.call_date,
                        doingPerson: itm.callUser.name+' '+itm.callUser.surname,
                        planedDate: moment(itm.next_call_date).format("DD.MM.YYYY"),
                        meetingContent: itm.description,
                        responsiblePerson:itm.callUser.name+' '+itm.callUser.surname
                    })
                })
            }).catch(e => {
                if (e.status == '404' || e.status == '406') {
                    if (e.data.message) {
                        this.$toast.error(this.$t('api.' + e.data.message));
                    }
                }
            })
        },
        async saveMeeting() {
            const isValid = await this.$refs.meetingForm.validate();
            if (isValid) {
                let formData = {
                    graduate_application_id: this.graduateApplicationId,
                    next_call_date: this.meetingForm.nextCallDate,
                    status: this.meetingForm.meetingStatus,
                    type: this.meetingForm.meetingType,
                    description: this.meetingForm.meetingDescription
                }
                GraduateApplicationService.saveMeeting(formData).then(response => {
                    this.$toast.success(this.$t('api.' + response.data.message));
                    this.formStatus = false
                    this.meetingForm = {}
                    this.$refs.meetingForm.reset();
                    this.get(this.graduateApplicationId)
                }).catch(e => {
                    if (e.status == '404' || e.status == '406') {
                        if (e.data.message) {
                            this.$toast.error(this.$t('api.' + e.data.message));
                        }
                    } else {
                        if (e.data.errors.next_call_date) {
                            this.$refs.meetingForm.errors.nextCallDate.push(e.data.errors.next_call_date[0]);
                        }
                        if (e.data.errors.status) {
                            this.$refs.meetingForm.errors.meetingStatus.push(e.data.errors.status[0]);
                        }
                        if (e.data.errors.type) {
                            this.$refs.meetingForm.errors.meetingType.push(e.data.errors.type[0]);
                        }
                        if (e.data.errors.description) {
                            this.$refs.meetingForm.errors.meetingDescription.push(e.data.errors.description[0]);
                        }
                    }
                })
            }
        },
        downloadExcel() {
            GraduateApplicationService.excelExportMeeting()
                .then(res => this._downloadFile(res, this.$t('meetings') + '.xlsx'))
                .catch(err => this.showErrors(err))
        },
        showImportModal() {
            this.$refs.formModal.$refs.commonModal.show()
        },
        sendFile() {
            let formData = new FormData();
            formData.append('file', this.form.file)
            GraduateApplicationService.excelImportMeeting(formData)
                .then(() => this.$toast.success(this.$t('proccess_success')))
                .catch(err => this.showErrors(err, this.$refs.importForm))
        }
    }
}
</script>
